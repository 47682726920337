import React from 'react'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'

import { handleLogin, isLoggedIn } from '../../services/auth'
import { mq, rem } from '../../utils/styles'

const Container = styled.form`
  margin: 60px auto 50px;
  text-align: center;

  input {
    border-radius: 4px;
    outline: none;
    padding: 8px;
    width: 250px;

    ${mq.lessThan('mobile')} {
      width: 220px;
    }
  }

  .message {
    font-size: ${rem(17)};
    margin-bottom: 13px;

    ${mq.lessThan('mobile')} {
      font-size: ${rem(14)};
    }
  }

  .error {
    color: #e01818;
    margin-top: 7px;

    @media (prefers-color-scheme: dark) {
      color: #ff5b5b;
    }
  }

  .inputField {
    input {
      border: 1px solid #333;
      margin-top: 15px;

      @media (prefers-color-scheme: dark) {
        border: 1px solid #f5f5f5;
      }

      ${mq.lessThan('mobile')} {
        font-size: ${rem(13)};
        margin-top: 12px;
      }
    }
  }

  .submit {
    background: #333;
    color: #fff;
    font-weight: 500;
    margin-top: 30px;

    @media (prefers-color-scheme: dark) {
      background: #f5f5f5;
      color: #333;
    }

    ${mq.lessThan('mobile')} {
      font-size: ${rem(13)};
      margin-top: 23px;
    }
  }
`

type LoginState = {
  email: string
  password: string
  error: Error | null
}

class Login extends React.Component<{}, LoginState> {
  constructor(props) {
    super(props)
    this.state = {
      email: ``,
      password: ``,
      error: null,
    }
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    } as Pick<LoginState, keyof LoginState>)
  }

  handleSubmit(event) {
    event.preventDefault()
    handleLogin(this.state)
      .then(() => {
        this.setState({
          error: null,
        })
      })
      .catch(error => {
        this.setState({
          error,
        })
      })
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/works/`)
    }

    return (
      <Container
        method="post"
        onSubmit={event => {
          this.handleSubmit(event)
          navigate(`/works/`)
        }}
      >
        <p className="message">Please enter ID and Password</p>
        <div className="inputField">
          <input
            type="text"
            name="email"
            onChange={this.handleUpdate}
            placeholder="ID"
          />
        </div>
        <div className="inputField">
          <input
            type="password"
            name="password"
            onChange={this.handleUpdate}
            placeholder="Password"
          />
        </div>

        <input className="submit" type="submit" value="Log In" />
        {this.state.error && (
          <p className="error">{this.state.error.message}</p>
        )}
      </Container>
    )
  }
}

export default Login
