import React from 'react'
import { Link, graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import styled from '@emotion/styled'

import { isLoggedIn } from '../services/auth'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import PageTitle from '../components/PageTitle'
import Login from '../components/Login'
import { mq, rem } from '../utils/styles'

const Container = styled.div`
  margin-top: 60px;

  ${mq.lessThan('mobile')} {
    margin-top: 30px;
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 35px;
`

const Item = styled.li`
  margin-bottom: 26px;

  ${mq.lessThan('mobile')} {
    width: 100%;
  }

  ${mq.between('mobile', 'mobileLandscape')} {
    margin-bottom: 23px;
    width: ${((1080 - 30 * 2) / 3 / 1080) * 100}%;

    &:not(:nth-child(3n)) {
      margin-right: ${(30 / 1080) * 100}%;
    }
  }

  ${mq.greaterThan('mobileLandscape')} {
    width: ${((1080 - 30 * 3) / 4 / 1080) * 100}%;

    &:not(:nth-child(4n)) {
      margin-right: ${(30 / 1080) * 100}%;
    }
  }

  a {
    display: block;
  }
`

const Summary = styled.div`
  margin-top: 10px;

  ${mq.lessThan('mobileLandscape')} {
    width: 100%;
  }

  p {
    font-size: ${rem(16)};
    font-weight: 500;
    letter-spacing: -0.03rem;
    line-height: 1.3;
    word-break: break-word;

    ${mq.lessThan('mobileLandscape')} {
      font-size: ${rem(15)};
    }
  }
`

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

type WorksPageProps = {
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            fields: {
              slug: string
            }
            frontmatter: {
              title: string
              thumbnail: {
                childImageSharp: {
                  fluid: FluidObject
                }
              }
            }
          }
        }
      ]
    }
  }
}

const WorksPage: React.FC<WorksPageProps> = ({ data }: WorksPageProps) => {
  const works = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="Works" />
      <Container>
        <PageTitle>Works</PageTitle>
        {isLoggedIn() ? (
          <List>
            {works.map(item => (
              <Item key={item.node.fields.slug}>
                <Link to={item.node.fields.slug}>
                  <Img
                    fluid={
                      item.node.frontmatter.thumbnail.childImageSharp.fluid
                    }
                  />
                  <Summary>
                    <p>{item.node.frontmatter.title}</p>
                  </Summary>
                </Link>
              </Item>
            ))}
          </List>
        ) : (
          <Login />
        )}
      </Container>
    </Layout>
  )
}

export default WorksPage
